var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tokens-price-scrollable-table"},[_c('div',{staticClass:"tokens-price-scrollable-table__table-container"},[_c('table',[_c('thead',[_c('tr',[_vm._l((_vm.headers),function(value,key){return _c('th',{key:key,staticClass:"text-center"},[_c('button',{staticClass:"w-full outline-none flex items-center justify-center gap-1",on:{"click":function($event){return _vm.sortValues(value)}}},[(_vm.currentSortKey === value)?_c('img',{staticClass:"w-4 h-4",class:{
                  'transform rotate-180': _vm.currentSortDirection === 'asc',
                },attrs:{"alt":"arrow","src":require("@/assets/img/arrow-up-order.svg")}}):_vm._e(),_vm._v(" "+_vm._s(value)+" ")])])}),_c('th')],2)]),(_vm.localItems)?_c('tbody',_vm._l((_vm.localItems),function(item){return _c('tr',{key:item.$id},[_c('td',[_c('token-image-render',{attrs:{"image-url":item.imageUrl}})],1),_c('td',[_c('token-cell-render',{staticClass:"md:pl-2",attrs:{"blockchainTagName":item.blockchainVersion ? item.blockchainVersion.tagName : null,"owner-address":item.marketInformation
                  ? item.marketInformation.creatorAddress
                  : null,"slug":item.marketInformation
                  ? item.marketInformation.slugDapp
                  : null,"symbol":item.symbol,"titleDapp":item.marketInformation
                  ? item.marketInformation.titleDapp
                  : null}})],1),_c('td',[_c('token-price-render',{staticClass:"px-8",attrs:{"pool-liquidity-u-s-d":item.shouldDisplayMarketInformation
                  ? item.marketInformation.poolLiquidityUSD
                  : null,"price":item.shouldDisplayMarketInformation
                  ? item.priceFormatted
                  : null}})],1),_c('td',[_c('percentage-progress-render',{staticClass:"px-8",attrs:{"percentage-value":item.shouldDisplayMarketInformation
                  ? item.priceVariationLastDayFormatted
                  : null,"input-class":"text-lg"}})],1),_c('td',[_c('percentage-progress-render',{staticClass:"px-8 md:px-4",attrs:{"percentage-value":item.shouldDisplayMarketInformation
                  ? item.priceVariationLastWeekFormatted
                  : null,"input-class":"text-lg"}})],1),_c('td',[_c('truncate-cell-render',{staticClass:"px-8 md:px-4",attrs:{"full-value":item.shouldDisplayMarketInformation
                  ? item.marketCapFormatted
                  : ''}})],1),_c('td',[_c('truncate-cell-render',{staticClass:"px-8 md:px-4",attrs:{"full-value":item.shouldDisplayMarketInformation
                  ? item.totalSupplyFormatted
                  : '',"symbol":item.shouldDisplayMarketInformation ? item.symbol : ''}})],1),_c('td',[_c('tokens-price-chart',{staticClass:"py-2 text-base xl:text-lg px-8 md:px-2",attrs:{"price-variation-last-week":item.marketInformation
                  ? item.priceVariationLastWeekFormatted
                  : 0,"prices":item.shouldDisplayMarketInformation && item.marketInformation
                  ? item.marketInformation.variationGraphValues
                  : []}})],1),_c('td',[_c('tokens-swap-button',{staticClass:"pr-8",class:item.shouldDisplayMarketInformation
                  ? 'py-2 text-base xl:text-lg'
                  : 'hidden',attrs:{"is-disabled":true,"is-swappable":_vm.swappableTokensSymbol.includes(item.symbol),"token":item}})],1)])}),0):_vm._e()]),_c('div',{staticClass:"w-full flex justify-center"},[_c('moon-loader',{attrs:{"loading":_vm.localItems.length <= 0}})],1)]),(_vm.showViewAll)?_c('div',{staticClass:"tokens-price-scrollable-table__view-all"},[_c('router-link',{staticClass:"horiz gap-2 bg-black btn btn--contrast h-10",attrs:{"to":"/tokens"}},[_c('span',{staticClass:"text-lg font-medium"},[_vm._v(" "+_vm._s(_vm.$translate('components.TokensPriceScrollableTable.viewAll'))+" ")]),_c('em',{staticClass:"text-2xl text-aquamarine fas fa-angle-right"})])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }